const devUrl = '/api';
const prodUrl = process.env.REACT_APP_PROD_URI;


export const baseUrl = process.env.REACT_APP_ENV === 'production' ? prodUrl : devUrl;

export const priceKey1 = process.env.REACT_APP_ENV === 'production' ? process.env.REACT_APP_STRIPE_LIVE_PRICE_ID : process.env.REACT_APP_STRIPE_TEST_PRICE_ID;
export const stripeKey = process.env.REACT_APP_ENV === 'production' ? process.env.REACT_APP_STRIPE_API_KEY : process.env.REACT_APP_STRIPE_API_TEST_KEY;

export const priceKey2 = process.env.REACT_APP_ENV === 'production' ? process.env.REACT_APP_STRIPE_LIVE_PRICE_ID2 : process.env.REACT_APP_STRIPE_TEST_PRICE_ID;

export const priceKeyBundle21 = process.env.REACT_APP_ENV === 'production' ? process.env.REACT_APP_STRIPE_LPID_B21 : process.env.REACT_APP_STRIPE_TEST_PRICE_ID;
export const priceKeyBundle12 = process.env.REACT_APP_ENV === 'production' ? process.env.REACT_APP_STRIPE_LPID_B12 : process.env.REACT_APP_STRIPE_TEST_PRICE_ID;
export const priceKeyBundle06 = process.env.REACT_APP_ENV === 'production' ? process.env.REACT_APP_STRIPE_LPID_B06 : process.env.REACT_APP_STRIPE_TEST_PRICE_ID;
export const priceKeyBundleFL = process.env.REACT_APP_ENV === 'production' ? process.env.REACT_APP_STRIPE_LPID_BFL : process.env.REACT_APP_STRIPE_TEST_PRICE_ID;

export const priceKeyTokens10 = process.env.REACT_APP_ENV === 'production' ? process.env.REACT_APP_STRIPE_LPID_T10 : process.env.REACT_APP_STRIPE_TEST_ID_T10;

export const priceKeyTokens40 = process.env.REACT_APP_ENV === 'production' ? process.env.REACT_APP_STRIPE_LPID_T40 : process.env.REACT_APP_STRIPE_TEST_ID_T40;

export const priceKeyGift = process.env.REACT_APP_ENV === 'production' ? process.env.REACT_APP_STRIPE_LPID_GIFT : process.env.REACT_APP_STRIPE_LPID_GIFT_test;

