import React from 'react';
import faqs from '../Data/faqs';
import styles from './Faqs.module.css';
import { Helmet } from 'react-helmet';

export default function Faqs() {
  const [activeIndex, setActiveIndex] = React.useState(null);

  function handleActiveIndex(index) {
    if (activeIndex === index) {
      setActiveIndex(null);
    } else {
      setActiveIndex(index);
    }
  }

  return (
    <div className={styles.faqContainer}>
      <Helmet>
        <title>Frequently Asked Questions - AI Pet Photos Generator</title>
        <meta name="description" content="Find answers to common questions about our AI pet portrait generator, custom furry art creation, and high-quality 4K pet images." />
        <meta name="keywords" content="AI pet portraits FAQ, furry art questions, pet image generator help, custom pet art information" />
        <link rel="canonical" href="https://aipetphotos.com/faq" />
      </Helmet>

      <h1 className={styles.faqHeading}>Frequently Asked Questions about AI Pet Photos</h1>
      <p>Learn more about our AI-powered pet portrait generator and how it creates stunning furry art of your cats and dogs.</p>
      <div className={styles.faqWrapper}>
        {faqs.map((faq, index) => (
          <div
            key={index}
            className={styles.faqItemIn}>
            <h2 className={styles.faqQuestion} onClick={() => handleActiveIndex(index)}>
              {faq.question}
            </h2>
            <p className={`${styles.faqAnswer} ${activeIndex === index ? styles.active : ''}`}>
              {faq.answer}
            </p>
          </div>
        ))}
      </div>
    </div>
  )
}
