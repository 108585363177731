import React from 'react'
import { baseUrl } from '../../config.js';
import { priceKeyTokens10, priceKeyTokens40 } from "../../config.js"
import axios from 'axios'

export default async function tokensCheckout(modelId) {

  const userForm = {
    product: 'Tokens_10', // product
  }

  const userForm2 = {
    product: 'Tokens_40', // product
  }


  async function handleSubmit() {
    // console.log('HANDELSUBMIT');

    let token = localStorage.getItem("token");


    try {
      // console.log(baseUrl);
      const { data } = await axios.post(`${baseUrl}/orders`, userForm2,
        {
          headers: { Authorization: `Bearer ${token}` },
        })

      const priceId2 = priceKeyTokens40;
      
      const response = await fetch(`${baseUrl}/checkout`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({ priceId: priceId2, orderId: data._id, modelId: modelId }) // include order ID
      });

      const checkoutData = await response.json();

      console.log("CHECKDATA", checkoutData);

      if (response.ok) {
        window.location.replace(checkoutData.url);
      } else {
        console.log('NOT OK');
      }
    } catch (error) {
      console.log(error);
    }
  }





  
  handleSubmit()
}