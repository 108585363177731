import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { baseUrl } from '../config.js';
import styles from './Generator.module.css';
import { Link } from 'react-router-dom';
import { getLoggedInUserId } from './Tools/auth.js';
import { useParams } from 'react-router-dom';
import tokensCheckout from './Tools/tokensCheckout.js';

export default function Generator() {
	const { petModelId } = useParams();
	const [userData, setUserData] = useState(undefined)
	const [ userInput, setUserInput ] = useState({
		model: 'dev',
		prompt: '',
		lora_scale: 1,
		num_outputs: 1,
		aspect_ratio: '1:1',
		output_format: 'webp',
		guidance_scale: 3.5,
		output_quality: 80,
		extra_lora_scale: 0.8,
		num_inference_steps: 28
	});
	const [ selectedPet, setSelectedPet ] = useState(petModelId || '');

	const [ petsData, setPetsData ] = useState([]);
	const [ outPut, setOutPut ] = useState({});
	const [ error, setError ] = useState('');
	const [ buttonLabel, setbuttonLabel ] = useState('Generate');
	const [ success, setSuccess ] = useState('');
	const [ isLoggedIn, setIsLoggedIn ] = useState(Boolean(getLoggedInUserId()));
	const [ copiedStates, setCopiedStates ] = useState({});

	const resetBut = () => setbuttonLabel('Generate');
	const resetError = () => setError('');

	function handleChange(e) {
		setUserInput((prev) => {
			return { ...prev, [e.target.name]: e.target.value };
		});
	}

	function handleReset(e) {
		e.preventDefault();
		setError(undefined);
		setUserInput({
			model: 'dev',
			prompt: '',
			lora_scale: 1,
			num_outputs: 1,
			aspect_ratio: '1:1',
			output_format: 'webp',
			guidance_scale: 3.5,
			output_quality: 80,
			extra_lora_scale: 0.8,
			num_inference_steps: 28
		});
	}

	React.useEffect(() => {
		const getModels = async () => {
			try {
				const token = localStorage.getItem('token');
				if (!token) return setError('Please log in first.');

				const response = await axios.get(`${baseUrl}/models-fl/`, {
					headers: { Authorization: `Bearer ${token}` }
				});
				console.log(response);

				if (response.data.length > 0) {
					setPetsData(response.data);
				}
			} catch (err) {
				console.log(err);
				setError('An error occurred fetching your images');
			}
		};

		// async function getPromptsFL() {
		// 	try {
		// 		const token = localStorage.getItem('token');
		// 		if (!token) return setError('Please log in first.');

		// 		const response = await axios.get(`${baseUrl}/prompts-fl/`, {
		// 			headers: { Authorization: `Bearer ${token}` }
		// 		});
		// 		console.log(response);
		// 		setOutPut(response.data);
		// 	} catch (e) {
		// 		console.log(e);
		// 	}
		// }

		getModels();
		// getPromptsFL();
	}, []);

	async function postPrompt(e) {
		e.preventDefault();
		resetError();
		setbuttonLabel('Sending..');
		setSuccess('');

		let token = localStorage.getItem('token');

		if (!token) {
			resetBut();
			return setError('You are not logged in, please log in.');
		}

		const promptData = {
			prompt: userInput,
			model: selectedPet
		};

		try {
			const response = await axios.post(`${baseUrl}/prompts-fl/`, promptData, {
				headers: { Authorization: `Bearer ${token}` }
			});

			console.log(response);

			if (response.status === 201) {
				setSuccess('Prompt submitted successfully.');
				resetBut();
				getInfo();
			}
		} catch (e) {
			console.log(e);
			setError('An error occurred while submitting the prompt. Please try again.');
			resetBut();
		}
	}

	function determineDisabled() {
		const selectedPetObject = petsData.find((pet) => pet._id === selectedPet);
		const isPetCompleted = selectedPetObject ? selectedPetObject.completed : false;
		const isPromptEmpty = userInput.prompt.trim() === '';

		return !isPetCompleted || isPromptEmpty;
	}
	useEffect(() => {
		async function pollModelPrompts() {
			try {
				const token = localStorage.getItem('token');
				if (!token) return;

				const response = await axios.get(`${baseUrl}/prompts/${selectedPet}`, {
					headers: { Authorization: `Bearer ${token}` }
				});

				if (response.data && Array.isArray(response.data)) {
					setOutPut(response.data);
				}
			} catch (error) {
				console.error('Error polling for prompts:', error);
			}
		}
		pollModelPrompts();
		// Set up an interval to poll for new prompts every 5 seconds
		const intervalId = setInterval(pollModelPrompts, 5000);

		// Clean up the interval when the component unmounts
		return () => clearInterval(intervalId);
	});

	function handleSaveImage(imgUrl) {
		const a = document.createElement("a");
		a.href = imgUrl;
		a.target = "_blank";
		a.download = true;
		a.rel = "noreferrer";
		document.body.appendChild(a);
		a.click();
		document.body.removeChild(a);
	}

	function handleCopyPrompt(promptId, prompt) {
		navigator.clipboard.writeText(prompt);
		setCopiedStates((prev) => ({ ...prev, [promptId]: true }));
		setTimeout(() => {
			setCopiedStates((prev) => ({ ...prev, [promptId]: false }));
		}, 2000);
	}

	async function getInfo() {
		try {
		  const token = localStorage.getItem("token");
		  const response = await axios.get(`${baseUrl}/profile`, {
			headers: { Authorization: `Bearer ${token}` },
		  });
		  if (response.status === 200) {
			setUserData(response.data);
		  } else {
			throw new Error("Could not get user info");
		  }
		} catch (err) {
		  console.log(err);
		}
	  }
	  useEffect(() => {
		getInfo();
	  }, []);
	
	return (
		<div className={styles.wrapper}>
			<h1>Generate Photos of Your Pet</h1>

			{!isLoggedIn ? (
				<div className={styles.petmodelWrap2}>
					<p>You havent logged in yet.</p>
					<Link to={'/login'}>
						<button className={styles.viewButton}>Login</button>
					</Link>
				</div>
			) : (
				!petsData.length > 0 && (
					<div className={styles.petmodelWrap2}>
						<p>
							You havent trained the AI <i>V3</i> with any pets yet.
						</p>
						<Link to={'/order'} state={{ product: 'Bundle_fl' }}>
							<button className={styles.viewButton}>ADD NEW PET</button>
						</Link>
					</div>
				)
			)}
        <div>


          {/* <button onClick={() => setInfo(!info)} className={styles.getInfo}> */}
            <small style={{ color: "black", marginRight: "7px" }}>
              Tokens available: {userData?.tokens}
            </small>
            {/* <small className={styles.tokenI}>i</small> */}
          {/* </button> */}

          <button
            className={styles.getTokens}
            onClick={() => tokensCheckout(petModelId)}
          >
            Get More
          </button>
        </div>
      
		

			<form className={styles.form}>
				<label>
					Select pet:
					<select value={selectedPet} onChange={(e) => setSelectedPet(e.target.value)}>
						{petsData.length > 0 && (
							<option value="" disabled>
								Select a pet
							</option>
						)}
						{petsData.length > 0 ? (
							petsData.map((pet, id) => (
								<option key={id} value={pet._id} disabled={!pet.completed}>
									{pet.data.petName}
									{!pet.completed && ' (still processing)'}
								</option>
							))
						) : (
							<option value="" disabled>
								Add New Pet to generate
							</option>
						)}
					</select>
				</label>
				<br />
				<label className={styles.labelPrompt}>
					Prompt:
					<small style={{ fontWeight: '300' }}>
						<b>TOK cat </b>or <b>TOK dog</b> reference your pet, use appropriately.
					</small>
					<textarea
						value={userInput.prompt}
						onChange={(e) => handleChange(e)}
						name="prompt"
						placeholder="TOK cat riding surfboard"
					/>
				</label>

				{error && <p className={styles.error}>{error}</p>}
				<button className={styles.submitButton} disabled={determineDisabled()} onClick={(e) => postPrompt(e)}>
					{buttonLabel}
					{selectedPet._id}
				</button>
				{success && <p className={styles.success}>{success}</p>}
			</form>

			<h3>Your Images</h3>
			<div className={styles.map}>
				{outPut.length > 0 ? (
					outPut.map((prompt, i) => {
						return prompt.displayUrl ? (
							<div key={i} className={styles.promptImageWrap}>
								<img
									key={i}
									src={prompt.displayUrl}
									alt="ai generated pet"
									className={styles.promptImage}
								/>
								<button
									onClick={() => handleSaveImage(prompt.displayUrl)}
									className={styles.saveImageBut}
								>
									Download Image
								</button>
								<p className={styles.promptText}>
									{prompt.prompt.prompt}
									<button
										className={styles.copyBut}
										onClick={() => handleCopyPrompt(i, prompt.prompt.prompt)}
									>
										{copiedStates[i] ? 'Copied!' : 'Copy prompt'}
									</button>
								</p>
							</div>
						) : (
							<div className={styles.imagePlaceholder}>
								<p>Awaiting image.</p>
							</div>
						);
					})
				) : (
					<p>Will appear here</p>
				)}
			</div>
			<h4>How It Works?</h4>
			<div>
				<div>1. Upload pet photos for AI training</div>
				<div> 2. Generate images from prompt</div>
			</div>
		</div>
	);
}
